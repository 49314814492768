$logoMobileHeight:50px;
$logoMobileWidth:50px;
$logoHeight:200px;
$logoWidth:200px;
$logoPadding:5px;
$mobileFooterHeight:222px;
$primaryBtnColor:#426EDB;

.checkout-page{
	position: absolute;
	background-color: #4f5356;
	height: 100%;
	overflow:hidden;
	.row{
		height: 100%;
		div{
			padding:0px;
		}
	}
	.pdf{
		background-color: #F8F8F8;
		.pdf-container{
			margin: 0 auto;
			.pdfObject{
				border:1px solid #898D90;
			}
		}
		.size{
			width: 100%;
			max-width: 800px;
			height: 100%;
			max-height: 1170px;
		}
	}
	.terminal{
		position: relative;
		background-color: white;
		box-shadow: 0px 3px 7px rgba(141, 141, 141, 0.25);
		bottom: inherit;
		.big-logo{
			img{
				width: $logoWidth;
				height: $logoHeight;
			}
		}
		.card{
			box-shadow: 0px 3px 7px rgba(141, 141, 141, 0.55);
			border-radius: 16px;
			width: 350px;
			margin: 0 auto;
			.checkout{
				.terminal-title{
					font-size: 20px;
					color: #898D90;
					font-weight: normal;
					padding-bottom: 20px;
				}
				.terminal-price{
					color: #363636;
					font-size: 50px;
					padding: 20px 0 20px 0;
				}
			}
			.success{
				.icon{
					font-size:100px;
					color:$primaryBtnColor;
				}
				.terminal-title{
					padding:20px 10px;
					font-size: 20px;
					color: #363636;
					font-weight: normal;
					small{
						font-size: 12px;
					}
				}
			}
			.terminal-download-btn{
				margin-bottom: 20px;
			}
			.terminal-pay-btn{
				background-color: $primaryBtnColor;
				border-color: transparent;
			}
			.terminal-btn{
				font-size: 20px;
				font-weight: bold !important;
				border-radius: 30px;
				width:250px;
				padding: 8px 20px;
				margin-top:25px;
				border-width: 3px;
			}
			.terminal-btn:active, .terminal-btn:focus{
				box-shadow: unset;
			}
			.terminal-pay-btn:hover{
				border-color: transparent;
			}
			.terminal-download-btn:hover, .terminal-download-btn:active{
				background-color: transparent;
				color:#74788d;
			}
		}
	}
	@media (max-width: 991px) {
		.mobile-header{
			background-color: white;
			width: 100%;
			height: $logoMobileHeight + ( 2 * $logoPadding);
			padding:$logoPadding !important;
			img{
				width: $logoMobileWidth;
				height: $logoMobileHeight;
			}
		}
		.pdf{
			position: absolute;
			top: $logoMobileHeight + ( 2 * $logoPadding);
			bottom:$mobileFooterHeight - 16px;
			.pdf-container{
				.pdfObject{
					border:0px solid #898D90;
				}
			}
		}
		.terminal{
			position: absolute;
			background-color: transparent;
			box-shadow: 0px 0px 0px rgba(255, 255, 255, 0);
			bottom: 0px;
			.card{
				border-bottom-right-radius: 0px;
				border-bottom-left-radius: 0px;
				width: 100%;
				margin-top: 0px !important;
				box-shadow: 0px 0px 7px rgba(141, 141, 141, 0.55);
				.checkout{
					.terminal-price{
						margin-top: 20px !important;
						font-size: 30px;
						padding: 0;
					}
					.terminal-download-btn{
						margin-top: 10px;
						border-color: transparent;
						color: $primaryBtnColor;
						margin-bottom: 0px;
					}
				}
				.terminal-btn{
					width:80%;
					margin-top: 15px;
				}
				.enabled-by{
					font-size: 12px;
					margin-top:10px !important;
				}
			}
		}
	}
}