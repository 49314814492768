/************** vertical timeline **************/

.verti-payflow{
  border-left: 3px dashed $white;
  margin: 0 10px;
  .event-list{
    position: relative;
    padding: 0px 0px 10px 30px;

    .payflow-timeline-dot{
      position: absolute;
      left: -9px;
      top: 25px;
      bottom: 10px;
      z-index: 9;
      font-size: 16px;
    }
    .event-content{
      position: relative;
      border: 2px solid $border-color;
      border-radius: 7px;
    }

  }
}

.start-flow{
  left: 0px;
  //box-shadow: 0px 3px 7px rgba(141, 141, 141, 0.55);
 // border-radius: 25px;
  //width: 200px;
 // margin: 0 auto;
}

